import { getImage } from "gatsby-plugin-image"

import Seo from "../../components/Seo"
import { Article, Section } from "../../components/containers"
import { ArticleTitle } from "../../components/headings"
import { Paragraph } from "../../components/text"

import { useCardImage } from "../../hooks/use-card-image"

const ContentNoticePage = ({ location }) => {
  const { largeCard } = useCardImage()
  const seoCard = getImage(largeCard)
  const pageTitle = "Content Notice"

  return (
    <>
      <Seo pageUrl={location.pathname} pageTitle={pageTitle} image={seoCard} />
      <Article>
        <ArticleTitle title="Content Notice" />
        <Section semantic={false}>
          <Paragraph>
            The Bitter Drop is rated 18 — a story intended for an adult
            audience, with explicit sexual content. The narrative uses reclaimed
            homophobic slurs and includes traumatic experiences from
            psycho-medical institutions.
          </Paragraph>
        </Section>
      </Article>
    </>
  )
}

export default ContentNoticePage
